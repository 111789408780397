@mixin flexCenter($dir, $h) {
    display: flex;
    flex-direction: $dir;
    justify-content: $h;
    align-items: center;
}

@mixin mdolueOne($width) {
    @include flexCenter("", space-between);
    margin-top: 50px;
    width: $width;
}

@mixin others($radius) {
    margin-top: 50px;
    border-radius: $radius;
    background-color: white;
    box-shadow: 0px 0px 5px 1px rgba(145, 145, 145, .5); 
}

.currency-hover-class li:hover {
  background-color: whitesmoke !important;
}

.fontsizeproduct {
  font-size: 14px !important;
}

.fontsizeproducttwo {
  font-size: 12px !important;
}

.main-container {
    // box-sizing: border-box;
    width: 100%;
    padding: 10px 0px 10px 0px !important;
    position: absolute;

    .inner-container {
        box-sizing: border-box;
        width: 100%;

        .title {
            font-size: 24px !important;
            font-weight: 500 !important;
        }

        .modules {
          margin-top: 10px;
    
            .currency {
                @include flexCenter("", center);
                width: 65px;
                height: 30px;
                border-radius: 4px;
                background-color: white;
                border: 1px solid #d9d9d9;
                cursor: default;
                transition: border 200ms ease;
            
                &:hover {
                  border: 1px solid black;
                }
            }
        }

        .graph {
          @include others(3px);
          margin-top: 20px;
        }

        .data {
            @include others(3px);
            padding-bottom: 10px;
            margin-top: 20px;

            .navBar {
                @include mdolueOne(500px);
                margin-top: 20px;
                height: 50px;
                padding: 0px 15px 0px 15px;
              
                .widthTags {
                  @include flexCenter("", center);
                  width: 150px;
                  cursor: default;
                }
              
                .selectedDimension {
                  color: black;
                }
                .notSelectedDimension {
                  color: #15c;
                }
            }
            
            .componentDiv {
                padding: 10px 15px;
            }
        }
    }
}

.parts {
    @include flexCenter("", flex-end);
    height: 50px;
    padding: 0px 15px 0px 15px;
    background-color: whitesmoke;
  
    .searchInput {
      margin-right: 100px;
      display: flex;
      border-radius: 2px;
  
      .searchField {
        width: 200px;
        height: 30px;
        border: 1px solid #bbbbbb;
        // border-right: none;
        border-radius: 2px;
  
        &:focus {
          outline: none;
        } 
      }
  
      .searchIcon {
        @include flexCenter("", center);
        height: 30px;
        width: 30px;
        border: 1px solid #bbbbbb;
        transition: box-shadow 200ms ease;
        border-radius: 2px;
  
        &:hover {
          box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.5);
        }
      }
    }
  
    .iconsDiv {
      display: flex;
  
      .iconSwap {
        @include flexCenter("", center);
        height: 30px;
        width: 30px;
        border: 1px solid #bbbbbb;
        border-radius: 2px;
      }
  
      .hoverEffect:hover {
        box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.5);
      }
    }
}

.headingParts {
    @include flexCenter(column, space-between);
    align-items: flex-end;
    width: 15%;
    font-weight: medium;
    padding: 0px 5px 5px 5px;
    border: 1px solid #ccc;
    border-right: none;
}

.hoverClass {
    transition: background-color 200ms ease;
    transition: box-shadow 200ms ease;
    cursor: default;
}
  
.hoverClass:hover {
    box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.5);
    background-color: whitesmoke;
}

.small {
  color: #898989;
  font-size: 12px;
}

.dropDown {
  margin-bottom: 6px;
  border: 1px solid #ccc;
}

.dropDown:focus {
  outline: none;
}

.special {
  position: absolute;
  pointer-events: none;
  right: 0;
  top: 0;
  width: 100%;
  height: 400px;
}

.graph {
  @include others(3px);
  width: 100%;
  margin-top: 20px;
}

.column {
  border: 1px solid #ccc;
}

.mainDropDownSales {
  position: absolute;
  right: 150px;
  top: 23px;
  border: 1px solid #ccc;
  border-radius: 3px;
}


 // Highlighting color for the div
.highlight {
  background-color: rgba(0, 0, 0, 0.25);
  z-index: 3000;
  height: 100%;
  width: 100%;
  position: absolute;
 }