@import "../../static/scss/variables.scss";
.MuiPaper-root-4 {
  box-shadow: none !important;
}

.ant-calendar-picker-input {
  width: 200px;
  padding: 0px;
}

.ant-calendar-range-picker-input {
  font-size: 12px;
  height: 25px;
}

.ant-calendar-picker {
  width: 200px;
}

.ant-calendar-picker-input {
  height: 28px !important;
}

.ant-calendar-picker-icon, .ant-calendar-picker-clear {
  left: 180px;
  top: 10px !important;
}