

.sales-over-time {
    display: flex;
    flex-direction: column;
}

.sales-over-time {
    hr {
        border: 5px solid #FF9C00;
        background-color: 5px solid #FF9C00;
        color: #FF9C00;
        border-top: #FF9C00 solid 5px;
        border-radius: 5px;
    }
}
.sales-over-time .pageName {
    font-size: 28px;
    font-weight: bold;
    color:  #1F2567;
}

.dot {
    height: 12px;
    width: 12px;
    background-color:  #FF9C00;
    border-radius: 50%;
    display: inline-block;
    margin-top: .5rem;
    margin-right: .5rem;
}

.sales-over-time .graphContainer {
    background-color: white;
    border: 1px solid #a9adda;
    box-shadow: 5px 5px 10px 10px #8888;
    border-radius: 10px;
    padding: 20px;  
}

.graphContainer {

    .dot {
        height: 12px;
        width: 12px;
        background-color:  #FF9C00;
        border-radius: 50%;
        display: inline-block;
        margin-top: 14px;
    }

    .pageName {
        font-size: 28px;
        font-weight: bold;
        color:  #1F2567;
    }
}