.containerDiv {
    width: 100%;
    padding: 10px 25px 50px 15px;
    // box-sizing: border-box;
    position: absolute;
    left: 10px;
}

.title {
    font-size: 24px !important;
    font-weight: bold;
}

.ant-calendar-picker-input {
    height: 38px;
}

.activeBarGraph {
    height: 400px;
    width: 100%;
    background-color: white;
    border-radius: 3px;
    padding: 20px 0px 20px 0px;
    box-shadow: 0px 0px 5px 1px rgba(145, 145, 145, .5); 
}

.activityShadowBox {
    height: 48px;
    width: 100%;
    background-color: whitesmoke;
    padding: 0px 15px 0px 15px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    box-shadow: 0px 0px 2px 1px rgba(211, 211, 211, 0.5); 
}

#backgroundShadow {
    box-shadow: 0px 2px 2px 1px rgba(207, 207, 207, 0.5);
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
}

.MuiInputBase-input {
    all: unset !important;
    width: 40px !important;
    color: black !important;
}

.deviceSelection {
    height: 28px;
    border: 1px solid #ccc;
    border-radius: 3px;
    width: 100px;
}

.deviceSelection:focus {
    outline: none;
}

.box {
    width: 300px;
    height: 100px;
}

.show-button {
    background-color: gray;
    color: white;
    border-radius: 3px;
    width: 100px;
}

.infoIcon {
    top: 0;
    right: 5px;
}

.hoverPathClass {
    cursor: pointer;
}


// sales performance css

.data-Module {
    height: 400px;
    width: 100%;
    background-color: white;
    border-radius: 3px;
    margin-top: 50px;
    box-shadow: 0px 0px 5px 2px rgba(170, 170, 170, 0.5);
}

.table-heading {
    font-weight: 500;
    height: 50px;
    padding: 0px 15px 0px 15px;
}

.ribbon {
    background-color: whitesmoke;
    height: 50px;
}

.table-heading {
    padding: 0px 15px 0px 15px;
}

.column-Box {
    height: 100%;
    width: 20%;
    font-weight: 400;
    padding: 5px 15px 5px 15px;
    border: 1px solid #ccc;
    transition-property: box-shadow, background-color;
    transition-duration: 200ms;
    transition-timing-function: ease;
}

.column-Box:hover {
    background-color: rgba(238, 238, 238, 0.5);
    box-shadow: inset 0px 0px 5px 1px rgba(145, 145, 145, .5); 
}

#react-table-temp {
    top: 100px;
    left: 20px;
}

.MuiTableRow-root .MuiTableCell-head {
font-size: 14px;
font-weight: 700;
}

.wish-listfont {
    font-size: 18px;
}

.wish-listfonttwo {
    font-size: 14px;
}