@import "../../../static/scss/variables.scss";

.reportWrapper .select__single-value {
  color: $grey !important;
  font-weight: 400 !important;
}
.text-grey {
  color: $grey;
  font-size: 14px;
}

.currencySymbol {
  font-size: 18px;
  font-weight: 500;
}

.reportWrapper {
  position: absolute;
  left: 10px !important;
  top: 50px  !important;
  right: 10px !important;
  padding-bottom: 20px;
}

.reportWrapper .title {
  font-size: 24px !important;
  font-weight: 500 !important;
}

.reportWrapper .rangePickr {
  width: 300px;
}

.reportWrapper .spaceBetween {
  display: flex;
  justify-content: space-between;
}

.reportWrapper .label {
  font-size: 16px;
  font-weight: 500;
  border-bottom: 2px dotted $light-light-grey;
}

.reportWrapper .link {
  font-size: 13px;
  color: $primary;
}

.currency-hover-class li:hover {
  background-color: whitesmoke !important;
}

.reportWrapper .numericValue {
  margin-top: 10px;
  font-size: 25px;
  font-weight: 500;
}

.reportWrapper .dash {
  font-weight: 500;
}

.reportWrapper .visitor {
  font-size: 13px;
}

.reportWrapper .graphTitle {
  font-weight: bold;
  font-size: 16px;
}

.reportWrapper .card {
  box-shadow: 1px 1px 3px $light-light-grey;
}

.reportWrapper .head {
  position: relative;
  bottom: 40px;
}

// .reportWrapper tspan,
// .reportWrapper .highcharts-point,
// .reportWrapper .highcharts-graph,
.reportWrapper .highcharts-legend,
.reportWrapper .highcharts-credits {
  display: none !important;
}

.selectOption {
  margin-left: 10px;
  height: 20px;
  width: 110px;
}

.reportWrapper .body {
  position: relative;
  bottom: 20px;

  .noData {
    color: #555555;
  }
}

.reportWrapper .selectBody {
  z-index: 10;
  width: 110px;
}

.fadeIn {
  -webkit-animation: fadeIn 0.5s; /* Safari 4+ */
  -moz-animation: fadeIn 0.5s; /* Fx 5+ */
  -o-animation: fadeIn 0.5s; /* Opera 12+ */
  animation: fadeIn 0.5s; /* IE 10+, Fx 29+ */
}
.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div {
  /* change div to reference your elements you put in <Masonry> */
  margin-bottom: 30px;
}

.highcharts-tooltip-box {
  fill: black;
  fill-opacity: 0.6;
  stroke-width: 0;
}

.highcharts-tooltip text {
  fill: white !important;
  text-shadow: 0 0 3px black;
}

.reportWrapper .react-table {
  position: relative;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.reportWrapper .table-icon {
  display: none;
}

.reportWrapper .time-range-div {
  display: none !important;
}

.reportWrapper .table-lable {
  display: none !important;
}

.reportWrapper .tableWrapper {
  position: relative;
}

.reportWrapper .table-head {
  position: relative;
  top: 19px;
  z-index: 2;
  background: white;
  border-radius: 3px;
  padding: 20px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
  -webkit-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
  -moz-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
}

.reportWrapper .filter-button {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
  -webkit-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
  -moz-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
  cursor: pointer;
}

.f-15 {
  font-size: 15px !important;
}

.reportWrapper .manage-filters-window {
  position: fixed;
  top: 60px;
  left: 220px;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.14);
  display: flex;
  z-index: 10;
}

.reportWrapper .manage-filters-box {
  width: 620px;
  margin: auto;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.4);
  position: relative;
}

.reportWrapper .delete {
  padding: 6px 11px;
  border: 1px solid #bbb;
  border-radius: 3px;
  color: $grey;
}

.reportWrapper .add-filter {
  padding: 6px 11px;
  border: 1px solid #bbb;
  border-radius: 3px;
  font-size: 14px;
}

.reportWrapper .apply-filter {
  padding: 6px 11px;
  border-radius: 3px;
  font-size: 14px;
  color: $white;
  background-color: $primary;
}

.reportWrapper .search .select__value-container {
  padding-left: 30px;
  overflow: visible;
}

.reportWrapper .search {
  position: relative;
}

.reportWrapper .search .fa-search {
  position: absolute;
  z-index: 1;
  padding: 11px;
  color: $light-grey;
}

.reportWrapper .manage-head {
  font-size: 18px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background-color: white;
}

.reportWrapper .filter-options {
  margin-top: 88px !important;
}

.css-4ljt47-MenuList {
  max-height: 190px !important;
}

.overflow {
  overflow-y: scroll;
  max-height: 480px;
}

.row-list {
  position: absolute;
  z-index: 10;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.24);
  -webkit-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.24);
  -moz-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.24);
  top: 50px;
  width: 250px;
  right: 0;
  height: 200px;
  overflow-y: scroll;
  background: white;
  cursor: pointer !important;
}

.db_ptr {
  cursor: pointer;
}

.objectKey {
  text-transform: uppercase;
  color: #637381;
  font-weight: 600;
  font-size: 14px;
  padding: 0.6rem 0.6rem;
  line-height: 1.6rem;
}

.reportWrapper .list {
  &:hover {
    background-color: $primary;
    color: white;
    z-index: 100;
  }
}

.edit-column-wrapper {
  position: relative;
}

.check {
  width: 20px;
  font-size: 12px;
}

.row-list span {
  font-size: 14px !important;
}

.reportHomeWrapper .overviewBody {
  position: relative;
  bottom: 30px;
}

.show {
  margin: auto;
  margin-top: 10px;
}

.reportHomeWrapper {
  color: #212b36;

  .sub {
    font-size: 16px;
    font-weight: 500;
  }

  .desc {
    font-size: 14px;
  }

  .subsub {
    font-size: 14px;
    font-weight: 500;
  }

  .number {
    font-size: 28px;
    font-weight: 500;
    text-align: left;
  }

  .report {
    font-size: 12px;
    font-weight: 500;
  }

  .value {
    font-size: 14px;
    color: #637381;
  }

  .links {
    color: #006fbb;
    font-size: 14px;
    cursor: pointer;
    &:hover {
      color: #084e8a;
    }
  }
}

.reportModal-header {
  font-size: 18px;
  font-weight: 500;
}

.reportModal-subText {
  font-size: 14px;
  font-weight: 100;
}

.reportModal-cancelBtn {
  margin-right: 20px;
  padding: 0.7rem 1rem;
  background: linear-gradient(180deg, #fff, #f9fafb);
  border: 1px solid #c4cdd5;
  box-shadow: 0 1px 0 0 rgba(22, 29, 37, 0.05);
  border-radius: 3px;
  line-height: 1;
  color: #212b36;
}

.reportModal-exportBtn {
  margin-right: 20px;
  border-radius: 3px;
  line-height: 1;
  padding: 0.7rem 1rem;
  background: linear-gradient(180deg, #6371c7, #5563c1);
  border-color: #3f4eae;
  box-shadow: inset 0 1px 0 0 #6774c8, 0 1px 0 0 rgba(22, 29, 37, 0.05), 0 0 0 0 transparent;
  color: #fff;
  fill: #fff;
}


.revenue-status .revenue-growth-data {
  display: flex;
  flex-direction: column;
  // font-size: calc(0.4vw + 0.2vh);
}

.revenue-status {
  // display: flex;
  // display: inline-block;
  // position: absolute;
  // top: 0;
  // right: 0;
  // float: right;
  flex-direction: column;
  // width: 40%;
  // margin: 0 auto;
  padding: 10px;
  border: 2px solid lightgray;
  // padding: 10px 0 0 45px;
  border-radius: 5px;
  background: white;
  overflow: hidden;
  width: 20%;
  // box-shadow: 5px 10px 8px #888888;

}

.revenue-heading {
  text-align: center;
}

.second-div {
  display: flex;
}

.revenue-heading {
  text-align: center;
}


.first-div {
  display: flex;
  // padding-bottom: 6px;
  justify-content: space-between;
}

.first-div-current-month {
  display: flex;
  flex-direction: column;
  float: right;
}

.filterCurrency {
  border: 1px solid #ccc;
  height: 30px;
  border-radius: 3px;
  font-size: 15px;
  margin-left: 10px;
}

.filterCurrency:focus {
  outline: none;
}