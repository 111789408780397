@import "../../../static/scss/newVariable.scss";

.product-container {
  position: absolute;
  left: 0px;
  right: 0px;
}

.mainDropDown {
  position: absolute;
  right: 150px;
  top: 19px;
  border: 1px solid #ccc;
  border-radius: 3px;
  font-size: 14px;
}

.mainDropDown:focus {
  outline: none;
}

.dimension-class {
  width: 600px;
}

.dimension-highlight {
  color: #15c;
}

.dimension-strip {
  background-color: whitesmoke;
  height: 50px;
}

.brand-class, .row-class {
  height: 25px;
}

.go-tag {
  height: 25px !important;
  width: 25px !important;
}

.brand-inputbox {
  border: 1px solid #d9d9d9;
}

.brand-inputbox {
  outline: none;
}

.searchIcon {
  width: 30px;
  height: 100%;
  border: 1px solid #d9d9d9;
  border-left: none;

  transition: box-shadow .2s ease;
}

.searchIcon:hover {
  box-shadow: inset 0px 0px 3px 1px rgba(0, 0, 0, 0.2);
}

.iconSwap {
  width: 25px;
  height: 25px;
  border: 1px solid #d9d9d9;

  transition: box-shadow .2s ease;
}

.iconSwap:hover {
  box-shadow: inset 0px 0px 3px 1px rgba(0, 0, 0, 0.2);
}

.box-shadow-class {
  box-shadow: inset 0px 0px 3px 1px rgba(0, 0, 0, 0.2);
}

.bl-none {
  border-left: none;
}

.bt-none {
  border-top: none !important;
}

.bl {
  border-left: 1px solid #d9d9d9;
}

.br {
  border-right: 1px solid #d9d9d9 !important;
}

.header-item-box {
  height: 60px;
  border: 1px solid #d9d9d9;
  border-right: none;
}

.header-item-box2 {
  height: 60px;
  border: 1px solid #d9d9d9;
}

.header-item2 {
  position: relative;
  height: 101%;
  border-right: none;
}

.header-item {
  position: relative;
  height: 101%;
  border-right: 1px solid #d9d9d9;
}

.hover-header-item:hover {
  box-shadow: inset 0px 0px 3px 1px rgba(0, 0, 0, 0.2);
  background-color: #f5f5f5;
}

.posabsolute-class {
  position: absolute;
  right: 0px;
  bottom: 0px; 
}

.row-class {
  width: 40px;
}

.disable-page {
  background-color: #a09999;
}