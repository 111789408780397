/** @format */

@import "../../../static/scss/variables.scss";

.overviewWrapper {
   // position: absolute;
   left: 255px;
   top: 100px;
   right: 35px;
   padding-bottom: 20px;
}

.overviewWrapper .header {
   position: absolute;
   z-index: 100;
   left: 255px;
   top: 63px;
   right: 0;
   padding: 15px 0;
   background: #f8f8f8;
   border-bottom: 1px solid $light-light-grey;
}

.ant-picker {
   min-width: 340px;
   height: 38px;
}

.header .title {
   margin-left: 20px;
}

.overviewWrapper .graph {
   width: 100%;
   display: flex;
   justify-content: space-between;
   background: #f8f8f8;
   margin-top: 3rem;
}

.overviewWrapper .barGraph {
   background: white;
   width: 100%;
}

.overviewWrapper .pieGraph {
   padding-top: 50px;
   background: white;
   align-content: center;
   display: none;
}

.barChart {
   display: flex;
   flex-direction: column;
}
.barChartHeader {
   margin-top: 1rem;
   display: flex;
   justify-content: space-evenly;
}
.barChartDatePicker {
   width: 250px;
   height: 100%;
}

.barChartDeviceSelection {
   width: 150px;
   float: right;
   z-index: 10;
}

// New Css Styling
.moreThanThousand {
   display: flex;
   //  max-width: calc(100% - 2rem);
   width: 100%;
   //  margin-top: 2rem;
   margin-bottom: 2rem;
   font-size: 14px;
   //  line-height: 2rem;
   --p-banner-background: var(--p-surface-highlight-subdued);
   --p-banner-border: var(--p-banner-border-highlight);
   transition: box-shadow 0.2s cubic-bezier(0.64, 0, 0.35, 1);
   transition-delay: 0.1s;
   box-shadow: var(--p-banner-border, none);
   border-radius: var(--p-border-radius-wide, 0 0 3px 3px);
   box-shadow: var(
      --p-banner-border,
      inset 0 3px 0 0 var(--p-override-none, #47c1bf),
      inset 0 0 0 0 transparent,
      0 0 0 1px rgba(63, 63, 68, 0.05),
      0 1px 3px 0 rgba(63, 63, 68, 0.15)
   );
   background-color: var(--p-banner-background, #eef9f9);
   padding: 1.1rem;
   position: relative;
}

.iconDiv {
   padding-right: 1.6rem;
   position: relative;
   display: flex;
   align-items: center;

   //  margin: 0.4rem;
   //  height: 2rem;
   width: 2rem;
   //  max-height: 100%;
   //  max-width: 100%;
}

.iconSpan {
   color: var(--p-surface, #fff);
   //  background-color: var(--p-surface-highlight,#b7ecec);
}

.bannerContent {
   overflow-wrap: break-word;
   padding: 0.4rem 0;
}

.exportImage {
   height: 20px;
}

.bannerContent > span {
   font-weight: var(--p-button-font-weight, 400);
   //  line-height: 1.6rem;
   text-transform: none;
   letter-spacing: normal;
   position: relative;
   display: flex;
   justify-content: center;
   align-items: center;
   min-width: 1px;
   min-height: 1px;
}

.noData {
   margin: 0 auto;
   padding-top: 210px;
   padding-left: 38%;
   font-size: 24px !important;
   font-weight: 500;
   color: grey;
   
}

.buttonExport {
   // margin: -.7rem -.8rem;
   // padding-left: .8rem;
   // padding-right: .8rem;
   background: transparent;
   border: 0;
   box-shadow: none;
   color: #006fbb;
   // outline: blueviolet;
   border-style: hidden;
}

.buttonExport > button::-moz-focus-inner {
   border: 0;
}

.buttonExport:hover {
   text-decoration: underline;
}

.buttonExport > span {
   font-weight: var(--p-button-font-weight, 400);
   line-height: 1.6rem;
   text-transform: none;
   letter-spacing: normal;
   position: relative;
   display: flex;
   justify-content: center;
   align-items: center;
   min-width: 1px;
   min-height: 1px;
}

.chartAndHeading {
   width: 100%;
}

.charts {
   border-radius: var(--p-border-radius-wide, 3px);
   background-color: var(--p-surface, #fff);
   box-shadow: var(
      --p-card-shadow,
      0 0 0 1px rgba(63, 63, 68, 0.05),
      0 1px 3px 0 rgba(63, 63, 68, 0.15)
   );
   outline: 0.1rem solid transparent;
}

.reportWrappers {
   position: absolute;
   width: 100%;
   // left: 255px
   top: 20px;
   padding-bottom: 20px;
   padding: 20px;
}

.reportWrappers .title {
   font-size: 24px !important;
   font-weight: 700 !important;
}

.reportWrappers .rangePickr {
   width: 300px;
}

.reportWrappers .spaceBetween {
   display: flex;
   justify-content: space-between;
}

.reportWrappers .label {
   font-size: 16px;
   font-weight: 500;
   border-bottom: 2px dotted $light-light-grey;
}

.reportWrappers .link {
   font-size: 13px;
   color: $primary;
}

.reportWrappers .numericValue {
   margin-top: 10px;
   font-size: 25px;
   font-weight: 500;
}

.reportWrappers .dash {
   font-weight: 500;
}

.reportWrappers .visitor {
   font-size: 13px;
}

.reportWrappers .graphTitle {
   font-weight: bold;
   font-size: 16px;
   padding: 10px;
}

.reportWrappers .card {
   box-shadow: 1px 1px 3px $light-light-grey;
}

.reportWrappers .head {
   position: relative;
   bottom: 40px;
}

.reportWrappers .body {
   position: relative;
   bottom: 20px;

   .noData {
      color: #555555;
   }
}

// Report modal button after export
.reportModal-header {
   font-size: 18px;
   font-weight: 500;
}

.reportModal-subText {
   font-size: 14px;
   font-weight: 100;
}

.reportModal-cancelBtn {
   margin-right: 20px;
   padding: 0.7rem 1rem;
   background: linear-gradient(180deg, #fff, #f9fafb);
   border: 1px solid #c4cdd5;
   box-shadow: 0 1px 0 0 rgba(22, 29, 37, 0.05);
   border-radius: 3px;
   line-height: 1;
   color: #212b36;
}

.reportModal-exportBtn {
   margin-right: 20px;
   border-radius: 3px;
   line-height: 1;
   padding: 0.7rem 1rem;
   background: linear-gradient(180deg, #6371c7, #5563c1);
   border-color: #3f4eae;
   box-shadow: inset 0 1px 0 0 #6774c8, 0 1px 0 0 rgba(22, 29, 37, 0.05), 0 0 0 0 transparent;
   color: #fff;
   fill: #fff;
}

.charts {
   margin-top: -30px !important;
   background: white;
   height: 410px;
   width: 100%
   //  margin-bottom: 40px;
}

// table scss

.tableWrapper {
   position: relative;
   top: 19px;
   border-radius: var(--p-border-radius-wide, 3px);
   background-color: var(--p-surface, #fff);
   box-shadow: var(
      --p-card-shadow,
      0 0 0 1px rgba(63, 63, 68, 0.05),
      0 1px 3px 0 rgba(63, 63, 68, 0.15)
   );
   outline: 0.1rem solid transparent;
}


.MuiInputBase-root{
   cursor: pointer !important;
 }