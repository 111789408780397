@import "../../static/scss/newVariable.scss";

.currency-container, .selectedLabel {
    height: 28px;
    border-radius: 3px;
    background-color: white;
    border: 1px solid #d9d9d9;
}

.currency-container {
    width: 40px;
}

.selectedLabel:focus {
    outline: none;
}

.groupBy-container > div > div > div {
    height: 28px;
}