.reportModal-cancelBtn {
  margin-right: 20px;
  padding: 0.7rem 1rem;
  background: linear-gradient(180deg, #fff, #f9fafb);
  border: 1px solid #c4cdd5;
  box-shadow: 0 1px 0 0 rgba(22, 29, 37, 0.05);
  border-radius: 3px;
  line-height: 1;
  color: #212b36;
}


.reportModal-exportBtn {
  margin-right: 20px;
  border-radius: 3px;
  line-height: 1;
  padding: 0.7rem 1rem;
  background: linear-gradient(180deg, #6371c7, #5563c1);
  border-color: #3f4eae;
  box-shadow: inset 0 1px 0 0 #6774c8, 0 1px 0 0 rgba(22, 29, 37, 0.05),
    0 0 0 0 transparent;
  color: #fff;
  fill: #fff;
}


.MuiInputBase-root{
  cursor: pointer !important;
}

.clip-loader{
    top: 9rem;
    width: 99%;
    height: 700px;
}

.seller_statestic_loader{
  position: relative;
  display: flex;
  left: 129%;
  top:1px;
  height: 250px;

}

.detail_loader{
  height: 55px;
  left: 48rem;
}

.noData{
  top: 4rem;
  width: 99%;
  height: 700px;
}

.dorpDownWidth {
  width: 131px !important;
}


.search-icon {
  position: absolute !important;
  left: 5px;
}


.search-box {
  height: 34px;
  border-radius: 3px;
  padding-left: 2rem;
  outline: none;
  border: 1px solid #ccc;
}

.search-box:focus {
  outline: none;
}


.searchIcon {
  padding: 2px;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
}



.reportModal-Btn {
  display: inline-block;
  padding: 0.4rem 1rem;
  font-size: 24px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  outline: none;
  background-color: #4CAF50;
  border: none;
  border-radius: 15px;
}

.reportModal-Btn:hover {background-color: #bfbfbf !important}

.reportModal-Btn:active {
  transform: translateY(2px);
}