.main-container {
    // box-sizing: border-box;
    width: 100%;
    padding: 30px 30px 30px 30px;
    position: absolute;

    .inner-container {
        box-sizing: border-box;
        width: 100%;
        padding: 0px 20px 0px 20px;

        .title {
            font-size: 28px;
            font-weight: bold;
        }

        #ac-chart-container {
            height: 100%;
        }

        .selection {
            position: absolute;
            top: 0;
            right: 0;
            border: 1px solid #ccc;
            border-radius: 3px;
            &:focus {
                outline: none;
            }
        }

        .dataTable {
            margin-top: 30px;
            border-radius: 5px;
            background-color: white;
            padding: 10px 10px 10px 10px;
            min-height: 300px;
            box-shadow: 0px 0px 5px 1px rgba(145, 145, 145, .5);

            .headingClass {
                padding: 5px 5px 5px 5px;
            }

            .small {
                color: grey;
                font-weight: lighter;
            }

            .borderClassData {
                border: 1px solid #ccc;
                border-top: none;
                border-right: none;
                width: 100%;
                padding: 5px 5px 5px 5px;
            }
        }
    }
}

.fontsizeproduct {
    font-size: 14px !important;
  }
  
  .fontsizeproducttwo {
    font-size: 12px !important;
  }