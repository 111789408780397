@import "../../static/scss/variables.scss";

.linkClassLast {
  background-color: burlywood;
}
.limitWrapper .count {
  padding: 13px 10px;
  color: $primary;
  font-size: 0.8rem;
}

.pagination .undefined {
  background: linear-gradient(60deg, $primary, $primary) !important;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 10px -5px #8181ec;
  padding: 4px 10px !important;
  font-size: 0.8rem;
  font-weight: 600;
}

.pagination .active a {
  color: $white !important;
}

.pagination li a {
  padding: 4px 10px;
  border-radius: 3px;
  display: block;
  color: $primary;
  font-size: 0.8rem;
  &:hover {
    text-decoration: none;
  }
}

.pagination .disabled a {
  opacity: 0.5;
}

.pagination {
  margin: 0;
}

.size {
  color: $primary;
  font-size: 11px;
  margin: 11px 0;
  padding: 4px 6px;
  &:hover {
    cursor: pointer;
  }
}

.divider {
  color: $primary;
  margin: 10px;
  font-size: 18px;
  font-weight: 300;
}

.limitWrapper {
  position: fixed;
  left: 245px;
  bottom: 5px;
  display: flex;
}

.selected {
  background: linear-gradient(60deg, $primary, $primary) !important;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 10px -5px #8181ec;
  font-weight: 600;
  border-radius: 3px;
  color: $white;
}
